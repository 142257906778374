import { createRouter, createWebHistory } from 'vue-router'
import MenuView from '../views/MenuView.vue'
import KartView from '../views/KartView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MenuView
  },
  {
    path: '/order',
    name: 'order',
    component: KartView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
