<template>
  <v-app>
    <v-app-bar app color="primary" dark >
      <v-toolbar-title>
        <v-row align="center">
          <v-col class="mr-0 pr-0 a-pointer" @click="routeTo(getMainPage)" cols="2" md="1"><v-img width="90%" src="./assets/logo.png"></v-img></v-col>
          <v-col class="ml-0 pl-0"> <b @click="routeTo(getMainPage)" class="a-pointer">{{title}}</b> </v-col>
          <v-spacer></v-spacer>
          <v-col class="mx-0 px-0">
             <v-icon style="position: absolute; top: 20px; right: 20px;" size="small" @click="routeTo('/order')" class="a-pointer mx-0 px-0">mdi-cart-variant</v-icon> 
             <p @click="routeTo('/order')" class="a-pointer mx-0 px-0" style="position: absolute; top: 25px; right: 5px; z-index: 100; text-shadow: -1px -1px 0 #24483f, 1px -1px 0 #24483f, -1px 1px 0 #24483f, 1px 1px 0 #24483f;">{{order}}</p>
          </v-col>
        </v-row>
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer class="mt-3 text-center d-flex flex-column" style="background-color: #24483f;" theme="dark">
      <div>
        <v-btn
          icon="mdi-linkedin"
          class="mx-4"
          variant="text"
          href="https://www.linkedin.com/in/alessandro-prati-895549295"
        ></v-btn>
        <v-btn
          icon="mdi-instagram"
          class="mx-4"
          variant="text"
          href="https://www.instagram.com/alessandro_prati_aak/"
        ></v-btn>
        <v-btn
          icon="mdi-gmail"
          class="mx-4"
          variant="text"
          href="mailto:alessandro.prati07@gmail.com"
        ></v-btn>
      </div>
      <v-divider></v-divider>
      <div>
        {{ new Date().getFullYear() }} - <strong>Alessandro Prati ®</strong>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import router from "./router"
import { globalContainer } from './store';

export default {
  name: 'App',
  data: () => ({
    items: [
      { title: "Home", icon: "mdi-movie-filter", link: "/"},
    ],
    drawer: false,
    title: process.env.VUE_APP_TITLE,
    order: undefined,
  }),
  
  created() {
    document.title = this.title;
    window.addEventListener('order-localstorage-changed', (event) => {
      this.order = event.detail.storage.split("+").length == 1 ? event.detail.storage.split("+")[0] != undefined && event.detail.storage.split("+")[0] != "" ? 1 : undefined : event.detail.storage.split("+").length;
      localStorage.lastUpdate = new Date().getTime();
    });
    if (localStorage.lastUpdate == undefined || this.isOlderThanSixHours(localStorage.lastUpdate)){
      localStorage.order = "";
    }
    if(localStorage.order != undefined)
      this.order = localStorage.order.split("+").length == 1 ? localStorage.order.split("+")[0] != undefined && localStorage.order.split("+")[0] != "" ? 1 : undefined : localStorage.order.split("+").length;
    },
  methods: {
    routeTo(link) {
      if (this.$route.path !== link) {
        router.push(link);
      } else {
        router.go();
      }
    },
    isOlderThanSixHours(dateTime) {
      const now = new Date();
      const sixHoursAgo = new Date(now.getTime() - (6 * 60 * 60 * 1000));
      return new Date(Number(dateTime)) < sixHoursAgo;
    }
  },
  computed: {
    getMainPage(){
      return globalContainer.MainPage;
    },
    showGridToggler(){
      return this.$route.path != "/order";
    }
  },
 
};
</script>

<style>
.a-pointer {
  cursor: pointer;
}
</style>
