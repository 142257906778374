<template>
  <v-card variant="flat" class="ma-0" :image="require('../assets/sfondovezzo.jpg')" height="20vh" style=" border-radius: 0px;">
    <v-card class="ma-0 pa-0" tile height="101%" variant="flat" color="transparent" style="background: linear-gradient(to bottom, transparent 25%, black 100%);">
      <v-card height="40%" variant="flat" color="transparent"></v-card>
      <v-card height="60%" variant="flat" color="transparent">
        <h1 style="width: fit-content; margin: auto; color: white;">Menù</h1>
        <p class="text-center" style="width: fit-content; margin: auto; color: white;">Metti in lista ciò che vuoi ordinare e mostralo alla cassa</p>
      </v-card>
    </v-card>
  </v-card>
  <RestCard v-for="(item, index) in items" :key="index" :item="item" class="mb-1" @itemclickadd="clickItem" @itemclickmore="clickItem" @itemclickless="clickItemRemove" />
</template>

<script>
  import RestCard from '../components/RestCard'
  import menu from "../services/menu"

  export default {
    name: 'MenuView',

    components: {
      RestCard,
    },
    methods:{
      updateList(){
        let orders = this.getOrders();
        this.items.map(el => {
          el.Count = undefined;
          return el;
        })
        orders.forEach(order => {
          if (this.items.find((el) => el.Nome == order.name) != undefined){
            this.items.find((el) => el.Nome == order.name).Count = order.count;
          }
        })
      },
      clickItem(item){
        if(localStorage.order == undefined || localStorage.order == ""){
          localStorage.order = item.Nome;
        }else{
          localStorage.order = localStorage.order +"+"+ item.Nome;
        }
        window.dispatchEvent(new CustomEvent('order-localstorage-changed', {
          detail: {
            storage: localStorage.getItem('order')
          }
        }));
        this.updateList();
      },
      clickItemRemove(item){
        if(localStorage.order == undefined || localStorage.order == ""){
          localStorage.order = undefined;
        }else{
          let temp = localStorage.order.split("+");
          const index = temp.indexOf(item.Nome);
          if (index !== -1) {
            temp.splice(index, 1);
          }
          localStorage.order = temp.join("+");
        }
        window.dispatchEvent(new CustomEvent('order-localstorage-changed', {
          detail: {
            storage: localStorage.getItem('order')
          }
        }));
        this.updateList();
      },
      getOrders(){
        if(localStorage.order == undefined)
          return []
        let order = localStorage.order.split("+");
        const counts = order.reduce((acc, str) => {
          acc[str] = (acc[str] || 0) + 1;
          return acc;
        }, {});
        return Object.keys(counts).map(name => ({
          name: name,
          count: counts[name]
        }));
      }
    },
    mounted() {
      this.updateList();
    },
    data: () => ({
      items: menu
    }),
  }
</script>
