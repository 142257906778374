export default [
  {
    Nome: "Primi",
    Ingredienti: "",
    Prezzo: "",
    Icons: []
  },
  {
    Nome: "Gnocchi di Castagne",
    Ingredienti: "Burro e Salvia / Sugo di Funghi / Gorgonzola",
    Prezzo: "6,50",
    Icons: []
  },
  {
    Nome: "Gnocchi di Patate",
    Ingredienti: "Burro e Salvia / Sugo di Funghi / Gorgonzola",
    Prezzo: "6,00",
    Icons: []
  },
  {
    Nome: "Tagliatelle di Castagne",
    Ingredienti: "Burro e Salvia / Sugo di Funghi / Gorgonzola",
    Prezzo: "6,00",
    Icons: []
  },
  {
    Nome: "Secondi",
    Ingredienti: "",
    Prezzo: "",
    Icons: []
  },
  {
    Nome: "Polenta e Funghi",
    Ingredienti: "",
    Prezzo: "5,50",
    Icons: []
  },
  {
    Nome: "Polenta e Gorgonzola",
    Ingredienti: "",
    Prezzo: "5,50",
    Icons: []
  },
  {
    Nome: "Maialino Cotto Sotto Terra ",
    Ingredienti: "",
    Prezzo: "9,50",
    Icons: []
  },
  {
    Nome: "Salame Cotto ",
    Ingredienti: "",
    Prezzo: "5,50",
    Icons: []
  },
  {
    Nome: "Contorni e Oltre Menù",
    Ingredienti: "",
    Prezzo: "",
    Icons: []
  },
  {
    Nome: "Caldarroste",
    Ingredienti: "",
    Prezzo: "3,00",
    Icons: []
  },
  {
    Nome: "Salume Misto",
    Ingredienti: "",
    Prezzo: "6,00",
    Icons: []
  },
  {
    Nome: "Panino Imbottito",
    Ingredienti: "",
    Prezzo: "3,00",
    Icons: []
  },
  {
    Nome: "Torte e Dolci",
    Ingredienti: "",
    Prezzo: "",
    Icons: []
  },
  {
    Nome: "Fetta Torta Dolce",
    Ingredienti: "",
    Prezzo: "3,00",
    Icons: []
  },
  {
    Nome: "Fetta Pattona",
    Ingredienti: "",
    Prezzo: "3,00",
    Icons: []
  },
  {
    Nome: "Sulachèn - Biscotti di castagne",
    Ingredienti: "sacchetto 200g",
    Prezzo: "5,00",
    Icons: []
  },
  {
    Nome: "Crepes Alle Castagne con Nutella",
    Ingredienti: "",
    Prezzo: "4,00",
    Icons: []
  },
  {
    Nome: "Torta Pattona Intera",
    Ingredienti: "",
    Prezzo: "12,00",
    Icons: []
  },
  {
    Nome: "Bevande",
    Ingredienti: "",
    Prezzo: "",
    Icons: []
  },
  {
    Nome: "Gutturnio",
    Ingredienti: "(Frizzante) Pusterla",
    Prezzo: "8,00",
    Icons: []
  },
  {
    Nome: "Ortrugo",
    Ingredienti: "Pusterla",
    Prezzo: "8,00",
    Icons: []
  },
  {
    Nome: "Gutturnio Classico",
    Ingredienti: "(Fermo) Pusterla",
    Prezzo: "8,00",
    Icons: []
  },
  {
    Nome: "Monterosso",
    Ingredienti: "Camorali",
    Prezzo: "8,00",
    Icons: []
  },
  {
    Nome: "Spumante",
    Ingredienti: "Camorali",
    Prezzo: "10,00",
    Icons: []
  },
  {
    Nome: "Bicchiere di Vino",
    Ingredienti: "",
    Prezzo: "1,50",
    Icons: []
  },
  {
    Nome: "Amaro",
    Ingredienti: "",
    Prezzo: "3,00",
    Icons: []
  },
  {
    Nome: "Acqua Grande",
    Ingredienti: "1L",
    Prezzo: "2,00",
    Icons: []
  },
  {
    Nome: "Acqua Piccola",
    Ingredienti: "0,5L",
    Prezzo: "1,00",
    Icons: []
  },
  {
    Nome: "Bibite",
    Ingredienti: "Cocacola, Fanta, The",
    Prezzo: "2,50",
    Icons: []
  },
  {
    Nome: "Birra alla Spina",
    Ingredienti: "Media 0,33cl",
    Prezzo: "3,50",
    Icons: []
  },
  {
    Nome: "Caffè",
    Ingredienti: "",
    Prezzo: "1,00",
    Icons: []
  },
  {
    Nome: "Caffè Corretto",
    Ingredienti: "",
    Prezzo: "1,50",
    Icons: []
  },
];