<template>
  <v-card color="white" class="ma-5 rounded-xl" elevation="8">
    <v-card :color="item.Prezzo === '' ? 'primary' : 'white'" :dark="item.Prezzo === ''" class="pt-2">
      <v-row>
        <v-col :cols="item.Prezzo === '' ? '12' : '8'">
          <h1 :style="`${item.Prezzo === '' ? 'text-center' : 'padding-left: 6px'}`" :class="`text-h6 font-weight-bold ${item.Prezzo === '' ? '' : 'ml-2'} ${item.Prezzo === '' ? 'text-center' : ''}`">{{ item.Nome }}</h1>
        </v-col>
        <v-col cols="4" v-if="item.Prezzo !== ''">
          <h1 class="text-h6 font-weight-bold ml-2">€ {{ item.Prezzo }}</h1>
        </v-col>
      </v-row>
       <h1 class="text-subtitle-1 mb-2 ml-2 text-medium-emphasis" :style="`padding-left: 6px; color: ${item.Prezzo != '' ? '#7b667b' : '#dfe1e1'} !important;`">{{ item.Ingredienti }}</h1>
       <v-row class="text-center ma-0 pa-0" v-if="item.Prezzo != ''">
        <v-col class=" ma-0 pa-0 pb-1">
          <v-btn v-if="item.Count == undefined" @click="$emit('itemclickadd', item)" style="border-radius: 20px;" color="primary" variant="tonal" append-icon="mdi-cart-plus">Aggiungi alla lista</v-btn>
          <v-btn v-if="item.Count != undefined" @click="$emit('itemclickless', item)" style="border-radius: 0;border-top-left-radius: 20px;border-bottom-left-radius: 20px;" color="primary" variant="tonal"><v-icon>mdi-minus</v-icon></v-btn>
          <v-btn v-if="item.Count != undefined" style="border-radius: 0;" color="primary" variant="tonal">{{item.Count}}</v-btn>
          <v-btn v-if="item.Count != undefined" @click="$emit('itemclickmore', item)" style="border-radius: 0;border-top-right-radius: 20px;border-bottom-right-radius: 20px;" color="primary" variant="tonal"><v-icon>mdi-plus</v-icon></v-btn>
        </v-col>
       </v-row>
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: 'RestCard',
  props: {
    item: {
      type: Object,
      default: () => ({
        Nome: "",
        Ingredienti: "",
        Prezzo: "00,00"
      })
    }
  }
}
</script>
