<template>
  <v-card style=" border-radius: 0px;" variant="flat" width="100%" height="100%">
    <v-card variant="flat" class="ma-0" :image="require('../assets/sfondovezzo.jpg')" height="15vh" style=" border-radius: 0px;">
      <v-card class="ma-0 pa-0" tile height="101%" variant="flat" color="transparent" style="background: linear-gradient(to bottom, transparent 55%, black 100%);">
        <v-card height="60%" variant="flat" color="transparent">
        </v-card>
        <v-card height="40%" variant="flat" color="transparent">
          <h1 style="width: fit-content; margin: auto; color: white;">Riepilogo</h1>
        </v-card>
      </v-card>
    </v-card>
      <v-card class="ma-5 rounded-xl " @click="routeTo(getMainPage)">
        <v-card color="#24483f" variant="tonal" class="pt-2">
          <v-row>
            <v-col class="text-center">
              <h1 style="padding-left: 6px; font-size: 18px;" :class="`font-weight-bold ml-2 `">Torna al Menu <v-icon class="ml-1" size="small" color="primary">mdi-silverware</v-icon></h1>
            </v-col>
          </v-row>
          <h1 class="text-subtitle-1 mb-2 ml-2 text-medium-emphasis" style="padding-left: 6px"></h1>
        </v-card>
      </v-card>
      <RestCard v-for="(item, index) in items" :key="index" :item="item" class="mb-1" @itemclickadd="clickItem" @itemclickmore="clickItem" @itemclickless="clickItemRemove" />
      <v-card v-if="items.length != 0" class="ma-5 rounded-xl " @click="clearDialog = true">
        <v-card color="#24483f" variant="tonal" class="pt-2">
          <v-row>
            <v-col class="text-center">
              <h1 style="padding-left: 6px; font-size: 18px;" :class="`font-weight-bold ml-2 `">Svuota Lista <v-icon class="ml-1" size="small" color="primary">mdi-cart-off</v-icon></h1>
            </v-col>
          </v-row>
          <h1 class="text-subtitle-1 mb-2 ml-2 text-medium-emphasis" style="padding-left: 6px"></h1>
        </v-card>
      </v-card>
      <v-card v-if="items.length != 0"  class="ma-5 rounded-xl mb-12" elevation="8">
        <v-card color="#24483f" class="pt-2 mb-0">
          <v-row>
            <v-col cols="7">
              <h1 style="padding-left: 6px" :class="`text-h5 font-weight-bold ml-2`">Totale</h1>
            </v-col>
            <v-col cols="5">
              <h1 class="text-h5 font-weight-bold ml-6">€ {{total}}</h1>
            </v-col>
          </v-row>
          <h1 class="text-subtitle-1 mb-2 ml-2 text-medium-emphasis" :style="`padding-left: 6px; color: #dfe1e1 !important;`">Paga alla cassa mostrando questa lista</h1>
          <h1 class="text-subtitle-1 mb-2 ml-2 text-medium-emphasis" style="padding-left: 6px"></h1>
        </v-card>
      </v-card>
      <v-row align="center">
        <v-spacer></v-spacer>
        <v-card v-if="items.length == 0" style="border-radius: 20px;" variant="tonal" class="text-center mt-4" align="center" width="85%">
          <v-card-text>
            <v-icon size="xx-large">mdi-cart-remove</v-icon>
            <h3>Il riepilogo è vuoto, consulta il nostro <a style="color: #24483f; text-decoration: none;" href="/">menù</a> per aggiungere degli articoli</h3>
          </v-card-text>
        </v-card>
        <v-spacer></v-spacer>
      </v-row>
      <v-dialog max-width="500" v-model="clearDialog">
          <v-card title="Svuota Lista">
            <v-card-text>
              Vuoi davvero svuotare la lista?
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary"
                @click="clearDialog = false"
              >No</v-btn>
              <v-spacer></v-spacer>
              <v-btn
                variant="tonal"
                color="primary"
                @click="clearList();"
              >Si</v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
  </v-card>
</template>

<script>
import RestCard from '../components/RestCard'
import { globalContainer } from '../store';
import { defineComponent } from 'vue';
import menu from "../services/menu"
import router from "../router"

export default defineComponent({
  name: 'OrderView',
  components: {
    RestCard,
  },
  methods:{
      routeTo(link) {
        if (this.$route.path !== link) {
          router.push(link);
        } else {
          router.go();
        }
      },
      updateList(){
        let orders = this.getOrders();
        this.items = orders;
         this.items = this.items.map((el) => {
          el.Nome = el.name;
          el.Prezzo = menu.find(elem => elem.Nome == el.Nome).Prezzo;
          el.Count = el.count;
          return el;
        });
        let price = 0;
        this.items.forEach((el ) => {
          price += (parseFloat(el.Prezzo.replace(",", ".")) * el.Count)
        })
        this.total = price.toFixed(2).replace(".", ",");
      },
      clearList(){
        localStorage.order = "";
        this.items = 0;
        this.total = 0;
        window.dispatchEvent(new CustomEvent('order-localstorage-changed', {
          detail: {
            storage: localStorage.getItem('order')
          }
        }));
        this.updateList();
        this.clearDialog = false;
      },
      clickItem(item){
        if(localStorage.order == undefined || localStorage.order == ""){
          localStorage.order = item.Nome;
        }else{
          localStorage.order = localStorage.order +"+"+ item.Nome;
        }
        window.dispatchEvent(new CustomEvent('order-localstorage-changed', {
          detail: {
            storage: localStorage.getItem('order')
          }
        }));
        this.updateList();
      },
      clickItemRemove(item){
        if(localStorage.order == undefined || localStorage.order == ""){
          localStorage.order = undefined;
        }else{
          let temp = localStorage.order.split("+");
          const index = temp.indexOf(item.Nome);
          if (index !== -1) {
            temp.splice(index, 1);
          }
          localStorage.order = temp.join("+");
        }
        window.dispatchEvent(new CustomEvent('order-localstorage-changed', {
          detail: {
            storage: localStorage.getItem('order')
          }
        }));
        this.updateList();
      },
      getOrders(){
        if(localStorage.order == undefined)
          return []
        let order = localStorage.order.split("+");
        if(order[0] == undefined || order[0] == ""){
          return []
        }
        const counts = order.reduce((acc, str) => {
          acc[str] = (acc[str] || 0) + 1;
          return acc;
        }, {});
        return Object.keys(counts).map(name => ({
          name: name,
          count: counts[name]
        })).sort((a, b) => {
  if (a.name > b.name) {
    return 1;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 0;
});
      }
    },
  mounted() {
    this.updateList();
  },
  data: () => ({
    items: [],
    total: 0,
    clearDialog: false
  }),
  computed: {
    getMainPage(){
      return globalContainer.MainPage;
    }
  },
});
</script>
